import React, { Component, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CSpinner } from "@coreui/react-pro";
import "./scss/style.scss";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Login from "./views/System/Login";

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

class App extends Component {
  render(): JSX.Element {
    return (
      <>
        <AuthenticatedTemplate>
          <Suspense fallback={<CSpinner color="primary" />}>
            <Routes>
              <Route path="/" element={<Navigate to="/upe/dashboard" />} />
              <Route path="*" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      </>
    );
  }
}

export default App;
