import React from "react";
import { MsalProvider } from "@azure/msal-react";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";

/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
 * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const MainContent = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

const MSALApp = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <MainContent />
    </MsalProvider>
  );
};

export default MSALApp;
