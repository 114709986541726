import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { msalInstance } from "../..";
import { loginRequest } from "../../authConfig";
import { logo } from "../../assets/brand/logo";

const Login = () => {
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCard className="text-white bg-dark py-5">
              <CCardBody className="text-center">
                <div>
                  <h2>Welcome</h2>
                  <p>This is User Provisioning Engine.</p>
                  <br />
                  <br />
                  <CButton
                    color="primary"
                    className="px-4"
                    onClick={() => msalInstance.loginRedirect(loginRequest)}
                  >
                    Login
                  </CButton>

                  <br />
                  <CIcon icon={logo} height={100} width={200} />
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
