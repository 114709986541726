export const logo = [
  "690 134",
  `
  <svg width="700" height="200" xmlns="http://www.w3.org/2000/svg">
  <metadata id="metadata8">image/svg+xml</metadata>
  <defs>
    <clipPath clipPathUnits="userSpaceOnUse" id="clipPath18">
      <path d="m-34.240227,1114.39l850.39,0l0,-850.39l-850.39,0l0,850.39z" id="path16"/>
    </clipPath>
  </defs>
  <g>
    <title>background</title>
    <rect fill="none" id="canvas_background" height="202" width="702" y="-1" x="-1"/>
  </g>
  <g>
    <title>Layer 1</title>
    <g id="g10" transform="matrix(0.618602991317252,0,0,-0.618602991317252,108.15320452240833,527.0466127802001) ">
      <g id="g12">
        <g id="g14" clip-path="url(#clipPath18)">
          <g id="g20">
            <path fill="#0061ab" fill-rule="nonzero" d="m128.42437,665.913086c0,-11.16 7.533,-17.787 16.75,-17.787c9.093,0 16.618,6.758 16.618,17.787c0,11.047 -7.525,18.049 -16.618,18.049c-9.217,0 -16.75,-6.871 -16.75,-18.049m37.788,36.881l11.684,-8.832l-7.132,-9.085c4.917,-5.589 6.74,-11.944 6.74,-18.964c0,-7.917 -2.982,-19.077 -13.506,-23.881c10.646,-5.318 13.244,-12.982 13.244,-21.161c0,-17.665 -13.506,-28.563 -32.068,-28.563c-18.563,0 -32.453,11.299 -32.453,28.563l15.703,0c0,-8.31 7.664,-13.768 16.75,-13.768c9.093,0 16.243,4.936 16.243,13.768c0,8.823 -8.327,12.852 -16.243,12.852c-19.993,0 -32.453,12.207 -32.453,32.19c0,20.002 14.535,32.47 32.453,32.47c5.074,0 10.262,-0.655 14.673,-3.636l6.365,8.047z" id="path22"/>
          </g>
          <path fill="#0061ab" fill-rule="nonzero" d="m204.357773,632.293l-15.72,0l0,90.747l15.72,0l0,-90.747z" id="path24"/>
          <g id="g26">
            <path fill="#0061ab" fill-rule="nonzero" d="m230.532661,664.230499c0,-9.739 5.833,-18.824 17.395,-18.824c11.552,0 17.394,9.085 17.394,18.824c0,9.608 -6.758,18.955 -17.394,18.955c-11.431,0 -17.395,-9.347 -17.395,-18.955m50.762,0c0,-18.432 -12.599,-33.358 -33.367,-33.358c-20.787,0 -33.246,14.926 -33.246,33.358c0,18.31 12.729,33.376 33.115,33.376c20.376,0 33.498,-15.066 33.498,-33.376" id="path28"/>
          </g>
          <g id="g30">
            <path fill="#0061ab" fill-rule="nonzero" d="m308.994667,664.370087c0,-11.57 8.562,-18.833 18.562,-18.833c10.132,0 18.18,7.655 18.18,18.833c0,11.544 -8.048,18.685 -18.18,18.685c-10,0 -18.562,-7.534 -18.562,-18.685m-1.046,58.67l0,-35.434c3.775,6.626 14.281,10.253 21.039,10.253c18.693,0 32.583,-11.422 32.583,-33.489c0,-21.039 -14.151,-33.498 -32.984,-33.498c-7.778,0 -15.703,2.598 -20.638,10.244l-1.038,-8.823l-14.805,0l0,90.747l15.843,0z" id="path32"/>
          </g>
          <g id="g34">
            <path fill="#0061ab" fill-rule="nonzero" d="m385.373085,664.370087c0,-12.207 8.439,-19.487 18.955,-19.487c24.936,0 24.936,38.825 0,38.825c-10.516,0 -18.955,-7.141 -18.955,-19.338m38.441,31.937l15.18,0l0,-64.014l-14.926,0l-0.777,9.347c-3.645,-7.525 -13.635,-11.169 -20.777,-11.291c-18.963,-0.131 -32.974,11.552 -32.974,34.021c0,22.067 14.665,33.611 33.358,33.489c8.571,0 16.748,-4.028 20.393,-10.384l0.523,8.832z" id="path36"/>
          </g>
          <path fill="#0061ab" fill-rule="nonzero" d="m467.462773,632.293l-15.703,0l0,90.747l15.703,0l0,-90.747z" id="path38"/>
          <g id="g40">
            <path fill="#00a0e4" fill-rule="nonzero" d="m227.671364,564.415009c0,-12.206 8.44,-19.478 18.964,-19.478c24.927,0 24.927,38.826 0,38.826c-10.524,0 -18.964,-7.141 -18.964,-19.348m38.433,31.938l15.189,0l0,-64.006l-14.927,0l-0.777,9.347c-3.644,-7.525 -13.636,-11.17 -20.768,-11.291c-18.963,-0.131 -32.983,11.561 -32.983,34.012c0,22.077 14.673,33.63 33.367,33.499c8.57,0 16.74,-4.02 20.384,-10.394l0.515,8.833z" id="path42"/>
          </g>
          <g id="g44">
            <path fill="#00a0e4" fill-rule="nonzero" d="m307.955574,564.415009c0,-11.16 8.048,-18.824 18.17,-18.824c9.993,0 18.563,7.272 18.563,18.824c0,11.17 -8.57,18.694 -18.563,18.694c-10.122,0 -18.17,-7.132 -18.17,-18.694m53.613,58.688l0,-90.756l-14.805,0l-1.037,8.824c-4.935,-7.655 -12.852,-10.254 -20.638,-10.254c-18.833,0 -32.975,12.469 -32.975,33.498c0,22.077 13.898,33.499 32.583,33.499c6.748,0 17.272,-3.636 21.03,-10.254l0,35.443l15.842,0z" id="path46"/>
          </g>
          <g id="g48">
            <path fill="#00a0e4" fill-rule="nonzero" d="m443.593788,596.493195l-28.102,-64.398l-17.673,0l-28.25,64.398l17.804,0l9.104,-21.17l10.183,-27.003l10.026,26.741l9.121,21.432l17.787,0z" id="path50"/>
          </g>
          <path fill="#00a0e4" fill-rule="nonzero" d="m451.689773,532.347l15.842,0l0,64.268l-15.842,0l0,-64.268zm17.263,82.063c0,-12.346 -18.685,-12.346 -18.685,0c0,12.32 18.685,12.32 18.685,0" id="path52"/>
          <g id="g54">
            <path fill="#00a0e4" fill-rule="nonzero" d="m523.193367,579.486298c-4.543,4.281 -9.748,5.711 -15.842,5.711c-7.533,0 -11.693,-2.337 -11.693,-6.365c0,-4.15 3.775,-6.495 11.945,-7.01c12.084,-0.784 27.404,-3.505 27.404,-20.524c0,-11.291 -9.224,-21.022 -27.525,-21.022c-10.123,0 -20.256,1.683 -29.611,11.422l7.787,11.3c4.551,-5.074 14.943,-8.824 22.084,-8.954c5.972,-0.14 11.553,2.981 11.553,7.655c0,4.42 -3.635,6.225 -12.721,6.748c-12.076,0.916 -26.488,5.318 -26.488,19.862c0,14.805 15.32,19.992 27.003,19.992c10,0 17.524,-1.944 24.928,-8.431l-8.824,-10.384z" id="path56"/>
          </g>
          <g id="g58">
            <path fill="#00a0e4" fill-rule="nonzero" d="m557.675789,564.285187c0,-9.73 5.842,-18.825 17.395,-18.825c11.562,0 17.404,9.095 17.404,18.825c0,9.616 -6.758,18.964 -17.404,18.964c-11.422,0 -17.395,-9.348 -17.395,-18.964m50.771,0c0,-18.441 -12.599,-33.368 -33.376,-33.368c-20.778,0 -33.237,14.927 -33.237,33.368c0,18.31 12.731,33.367 33.115,33.367c20.375,0 33.498,-15.057 33.498,-33.367" id="path60"/>
          </g>
          <g id="g62">
            <path fill="#00a0e4" fill-rule="nonzero" d="m633.488289,596.353485l1.16,-7.394c4.934,7.908 11.561,9.085 18.047,9.085c6.627,0 12.992,-2.598 16.496,-6.103l-7.141,-13.768c-3.242,2.73 -6.232,4.159 -11.429,4.159c-8.301,0 -15.973,-4.412 -15.973,-16.225l0,-33.761l-15.834,0l0,64.007l14.674,0z" id="path64"/>
          </g>
          <g id="g66">
            <path fill="#00874b" fill-rule="nonzero" d="m372.763161,811.706482l12.135,0l-12.135,-12.135l-21.026,0l-12.136,12.135l33.162,0z" id="path68"/>
          </g>
          <g id="g70">
            <path fill="#79b935" fill-rule="nonzero" d="m384.897987,766.401398l-12.135,12.147l0,21.023l12.135,12.135" id="path72"/>
          </g>
          <g id="g74">
            <path fill="#00ade7" fill-rule="nonzero" d="m409.15818,811.706482l-12.135,0l12.135,-12.135l21.027,0l12.135,12.135l-33.162,0z" id="path76"/>
          </g>
          <g id="g78">
            <path fill="#1e7abe" fill-rule="nonzero" d="m397.023476,766.401398l12.135,12.147l0,21.023l-12.135,12.135" id="path80"/>
          </g>
          <g id="g82">
            <path fill="#e95323" fill-rule="nonzero" d="m434.896461,848.109375l-49.139,0l12.145,-12.143l36.994,0c17.939,0 32.551,-15.588 32.551,-34.775l0,-34.772l12.135,-12.13l0,46.902c0,25.868 -20.04,46.918 -44.686,46.918" id="path84"/>
          </g>
          <g id="g86">
            <path fill="#f39415" fill-rule="nonzero" d="m347.007362,766.414612c-17.939,0 -32.542,15.602 -32.542,34.776c0,19.187 14.603,34.776 32.542,34.776l27.094,0l-12.143,12.143l-14.951,0c-24.631,0 -44.677,-21.05 -44.677,-46.919c0,-25.86 20.046,-46.901 44.677,-46.901l26.244,0l-12.143,12.125l-14.101,0z" id="path88"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

`,
];
